import React, { Component } from 'react';
import db from './auth/fire-base.js';
import Avatar from '@material-ui/core/Avatar';

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      name: "",
      msg: "",
      uid: "",
      pic: "",
      messages: {},
    };

  }

  componentDidMount() {
   if (this.props.pincode !== undefined && this.props.pincode !== null && this.props.pincode !== "") {
          this.commentList = db.firestore().collection("comment").doc(this.props.pincode);
          this.commentList
          .onSnapshot((doc) => {
             if (doc.exists){
                 var comments = doc.data().comments;
                 this.setState({ messages: comments });
                 this.scroll()
             }
             else {
               db.firestore().collection("pins").doc(this.props.pincode).get().then( (doc) => {
                   if(doc.exists) {
                       db.firestore().collection("comment").doc(this.props.pincode).set(
                          {comments: []}
                       ).then((userprofile) => {
                           this.commentList = db.firestore().collection("comment").doc(this.props.pincode);
                           this.commentList
                           .onSnapshot((doc) => {
                              if (doc.exists){
                                  var comments = doc.data().comments;
                                  this.setState({ messages: comments });
                              }});
                       });
                   }
               });
             }
           });

          this.getUser();
       }

      this.handleNewMessages = snap => {
        // if not null then update state
         if (snap.val()) this.setState({ messages: snap.val() });
      };
    }

    async getUser() {
    await db.auth()
      .onAuthStateChanged((user) => {
          if(user) {
          document.getElementById("typeMessage").hidden = false
            db.firestore()
              .collection("users")
              .doc(user.uid)
              .get()
              .then((userprofile) => {
                this.setState({username: userprofile.data().username});
                this.setState({name: userprofile.data().name});
                this.setState({uid: user.uid});
                if(userprofile.data().displayPic !== undefined)
                    this.setState({pic: userprofile.data().displayPic});

              });
          }
      });
  }

  getDate(date, map) {

    if(!map.includes(date.toDate().toDateString())) {
        map.push(date.toDate().toDateString());
        return date.toDate().toDateString();
    }

    return undefined;
  }

  scroll() {

    var div = document.getElementById("messages")
    if(div !== undefined && div !== null)
      if(!div.hidden)
        div.scrollTop = div.scrollHeight - div.clientHeight

  }

  handleMsgChange = e => this.setState({ msg: e.target.value });
  handleKeyDown = e => {
    const { msg, username, name, uid, pic } = this.state;
    if (e.key === "Enter") {
      db.firestore().runTransaction(t => {
          if(this.props.pincode !== undefined && this.props.pincode !== null && this.props.pincode !== "") {
            return t.get(this.commentList)
              .then(doc => {
                  const largerArray = doc.get('comments');
                  largerArray.push({ msg: msg, username: username, name: name, uid: uid, pic: pic,time: new Date() });
                  t.update(this.commentList, 'comments', largerArray);
              }).catch(err =>{console.log(err)});
          }
      }).then(result => {
        //console.log('Transaction success!');
      }).catch(err => {
        //console.log('Transaction failure:', err);
      });
      // clear the field
      this.setState({ msg: "" });
    }
  };
  render() {
    const map = [];
    return (
      <div className="App">
        {(
            <div className="chat">
              <div id="messages" className="messages" style={{ overflowY: "scroll", overflowX: "hidden", height:"600px" }}>
                {Object.keys(this.state.messages).map((message, i) => {
                    return (
                      <div style={{paddingLeft: "25px", width: "100%" }} key={i}>

                        {this.getDate(this.state.messages[message]["time"], map) ? (
                            <div><p>{this.state.messages[message]["time"].toDate().toDateString()}</p><hr /></div>

                          ) : (
                                <div></div>
                              )}

                        <div className = "row">
                          {this.state.messages[message]["pic"] ? (
                          <Avatar style={{ display: "block"}} src={this.state.messages[message]["pic"]} ></Avatar>
                          ) : (
                               <Avatar></Avatar>
                             )}
                          <div className= "col-md-6 sm-6 pin-owner">{this.state.messages[message]["name"]}<br/> <span className = "pin-content">{this.state.messages[message]["msg"]}</span></div>
                          <div style={{float: "right"}}className= "pin-time">{this.state.messages[message]["time"].toDate().toLocaleTimeString()}</div>
                          <hr/>
                        </div>
                      </div>
                    );
                })}
              </div>

              <div><input id="typeMessage" className="home-pin-input" placeholder="Type Your Reply" onChange={this.handleMsgChange} onKeyDown={this.handleKeyDown} value={this.state.msg} style={{"width":"100%"}} hidden/></div>
             <br/>
            </div>
          )}
      </div>
    );
  }
}

export default Comment;