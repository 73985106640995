import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import SearchComponent from "../components/search-component";

const SearchPage = () => (
  
 <SearchComponent></SearchComponent>
  
)

export default SearchPage
