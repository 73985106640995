import React from "react"
import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"
import Linkedin from "../images/linkedin.png"
import Mail from "../images/mail.png"
import firebase from "../../src/components/auth/fire-base.js"
import Avatar from "@material-ui/core/Avatar"
import FileIcon from "../images/file-icon.png"

import Modal from "react-bootstrap/Modal"
import { IoMdClose } from "react-icons/io"
import { ReactTinyLink } from "react-tiny-link"
import Linkify from "react-linkify"
import ReactPlayer from "react-player"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from "react-share"
import ConfirmationModal from "./confirmation-modal"
import Ellipsis from "../images/ellipsis.png"
import Dropdown from "react-bootstrap/Dropdown"

const Search = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const text = "Are you sure you want to delete this pin?"

  const showModal = () => {
    setIsOpen(true)
  }

  const hideModal = () => {
    firebase.analytics()
      .logEvent('Share Pin was open', { name: 'Share Pin'})
    setIsOpen(false)
  }

  const showConfirmation = () => {
    setIsDelete(true)
  }

  const hideConfirmation = () => {
    setIsDelete(false)
  }

  function copy(copy) {
    var copyText = document.getElementById(copy)
    copyText.select()
    copyText.setSelectionRange(0, 99999) /*For mobile devices*/
    document.execCommand("copy")
  }

  const imageLoadError = e => {
    document.getElementById("div-image").hidden = true
    document.getElementById("div-download").hidden = false
  }

  var link = "";
  if (typeof window !== `undefined`) {
    link = window.location.href;
  }

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
     <a
       href=""
       ref={ref}
       onClick={e => {
         e.preventDefault()
         onClick(e)
       }}
     >
       <div>
           <img id="toggle" ref={ref} src={Ellipsis} style={{marginLeft: "60%", marginTop: "2%"}}/>
       </div>
     </a>
   ))

  return (
    <>
      <div className="">
        <div className="row pin-btn-row">
          <div className = "col-md-6 col-9" id="owner-img">
            <Avatar
              style={{ width: "70px", height: "70px", marginRight: "24px", float:"left" }}
              src={props.img} hidden
            ></Avatar>
             <div id="owner" ref={props.owner} hidden></div>
             <div
            className="pin-code"
            id="pincode"
            ref={props.pincodeElem}
          ></div>
          <p id="streamName" className="home_pinID">{props.streamName}</p>
          </div>

          <div className="col-md-6 col-3">

            <Dropdown ref={props.update} style={{ display: `inline-flex`, marginLeft: `60%`, marginTop: "10px" }} hidden>
              <Dropdown.Toggle as={CustomToggle} style={{paddingTop: `50px` }}></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={props.launchEditModal} >Edit Pin</Dropdown.Item>
                <Dropdown.Item onClick={showConfirmation}>Delete Pin</Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>
          <button
              className="share-btn"
              style={{
                backgroundColor: "white",
                border: "1px solid #e8573a",
                color: "#e8573a",
              }}
              ref={props.bookmark}
             hidden
            >
              Save
            </button>
            <button className="share-btn"
              onClick={showModal}
              ref={props.share}
              hidden
              >
              Share
            </button>
           <div>

           <button className="share-btn"
              ref={props.subscribe}
              style={{
                backgroundColor: "white",
                border: "1px solid #e8573a",
                color: "#e8573a",
              }}
              hidden
              >
              Subscribe
           </button>
           </div>
          </div>
          </div>
         
          {/* <div
            className="col-md-6 pin-owner col-6"
            id="pincode"
            ref={props.pincodeElem}
          ></div> */}
         <div className="row">
          <div className="col-md-12 pin-content-div" style={{ overflowY: "scroll", overflowX: "hidden", height:"700px", width: "100%" }}>
            <Linkify>
              <span id="title" ref={props.title}></span>
            </Linkify>
            <div>
              {props.fileURL ? (
                <div id="div-file" className="col">
                  <br />
                  <img
                    id="div-image"
                    src={props.fileURL}
                    width="30%"
                    className="offset-4"
                    onError={imageLoadError}
                  ></img>
                  <a
                    id="div-download"
                    target="_blank"
                    href={props.fileURL}
                    className="offset-4"
                    download
                    hidden
                  >
                    <div
                      className="col-6 offset-3 text-center"
                      style={{ border: "1px solid grey" }}
                    >
                      <img src={FileIcon} width="30%"></img>
                      <br />
                      <span>Download File</span>
                    </div>
                    <br />
                  </a>
                </div>
              ) : (
                ""
              )}
              {props.pincodeURLS.map((val, index) => {
                let additional_html = ""

                if (val.includes("youtube.com/watch")) {
                  additional_html = (
                    <div className="col" key={"addhtml" + index}>
                      <br />
                      <ReactPlayer url={val} />
                    </div>
                  )
                } else {
                  additional_html = (
                    <div className="col" key={"addhtml" + index}>
                      <br />
                      <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        maxLine={2}
                        minLine={1}
                        url={val}
                      />
                    </div>
                  )
                }

                return additional_html
              })}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal show={isDelete} onHide={hideConfirmation} action={props.deletePin} title="Delete Pin" text={text} ></ConfirmationModal>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>
            <h6 className="new-pin-title">
              <IoMdClose onClick={hideModal} style={{ cursor: "pointer" }}></IoMdClose>
              &nbsp;Share PIN
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="copy-link-wrapper">
            <input
              type="text"
              className="modal-input"
              value={props.copyLink}
              id="copyLink"
              readOnly
            ></input>{" "}
            <button className="copy-link-btn" onClick={() => copy("copyLink")}>
              Copy Link
            </button>
          </div>
          <div className="copy-pin-wrapper">
            <input
              type="text"
              className="modal-input"
              value={props.copyPin}
              id="copyPin"
              readOnly
            ></input>{" "}
            <button className="copy-pin-btn" onClick={() => copy("copyPin")}>
              Copy Pin
            </button>
          </div>
          <div className="modal-social-media-wrapper">
            <FacebookShareButton url={link}> <img src={Facebook} className="modal-social-media-icon" alt=""/></FacebookShareButton>
            <TwitterShareButton url={link}><img src={Twitter} className="modal-social-media-icon" alt="" /></TwitterShareButton>
            <LinkedinShareButton url={link}> <img src={Linkedin} className="modal-social-media-icon" alt=""/></LinkedinShareButton>
            <EmailShareButton url={link}><img src={Mail} className="modal-social-media-icon" /></EmailShareButton>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="post-btn" onClick={() => hideModal()}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Search
