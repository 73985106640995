import React, { Component } from "react"
import Sidebar from "react-sidebar"
import Search from "./search"
import firebase from "./auth/fire-base.js"
import Comment from "./comment"
import { FaArrowLeft } from "react-icons/fa"
import { Avatar } from "@material-ui/core"
import NewPinModal from "../../src/components/newpin-modal"
import Logo from "../images/pin-logo.jpg"
import {navigate} from "gatsby"
import InputModal from "./input-modal"
import Dropdown from "react-bootstrap/Dropdown"

class SearchComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      hasUser: false,
      userId: "",
      creatorId: "",
      additionalPostHTML: [],
      editOpen: false,
      pic: "",
      stream: "",
      viewPin: true,
      password: "",
      errorHidden: true,
      profilePic: ""
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.title = React.createRef()
    this.ownerImg = React.createRef()
    this.pincodeElem = React.createRef()
    this.owner = React.createRef()
    this.avatar = React.createRef()
    this.update = React.createRef()
    this.subscribeButton = React.createRef()
    this.shareButton = React.createRef()
    this.copyPin = ""
    this.copyLink = ""
    this.bookmark = React.createRef()
    this.pincodeURLS = []
    this.fileURL = null
    this.launchEditModal = this.launchEditModal.bind(this)
    this.hideEditModal = this.hideEditModal.bind(this)
    this.deletePin = this.deletePin.bind(this)
    this.passwordInput = React.createRef()
  }

  componentDidMount() {
    this.getCurrentUserId()
  }

  CustomToggle = React.forwardRef(({ onClick }, ref) => (
      <a
        className="profile-icon"
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault()
          onClick(e)
        }}
      >
        <div>
        <Avatar
              id="profilePic"
              style={{ width: "40px", height: "40px", float:"right", marginTop:"12px"}} src={this.state.profilePic}
        ></Avatar>
        </div>
      </a>
    ))

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  onSetInputDialogOpen(open) {
    this.setState({ errorHidden: true })
    this.setState({ inputDialogOpen: open })
  }

  async requiresPassword() {
    await firebase
        .firestore()
        .collection("pins")
        .doc(this.getPincode()).get().then((pin)=>{
          if(pin.exists) {
            var password = pin.data().password ? pin.data().password : ""
            var creator = pin.data().creator ? pin.data().creator : ""

            if(password !== "") {
              if(creator.id !== this.state.userId) {
                this.setState({ password: password })
                this.onSetInputDialogOpen(true)
              } else {
                this.setState({ viewPin: false })
                this.loadPin()
              }
            } else {
              this.setState({ viewPin: false })
              this.loadPin()
            }
          } else {
             navigate("/404")
          }
        })
  }

  async getCurrentUserId() {
    await firebase.auth().onAuthStateChanged(user => {
       if(user)
         this.setState({ userId: user.uid })
       this.requiresPassword()
    })
  }

  loadPin() {
    this.getAuthor()
    this.setShareInputs()
    this.bookmark.current.onclick = () => this.addBookmark()
  }

  authPin() {
    var password = ""
    if (this.passwordInput.current !== null)
      password = this.passwordInput.current.value

    if(this.state.password === password) {
      this.loadPin()
      this.setState({viewPin: false})
      this.onSetInputDialogOpen(false)
    } else {
      this.setState({ errorHidden: false })
      setTimeout(()=>{ this.setState({ errorHidden: true }) }, 2000)
    }
  }

  getCurrentUser() {
    const { stream } = this.state
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ hasUser: true, userId: user.uid })
        this.bookmarked()
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get().then((doc)=>{
            var pic = doc.data().displayPic ? doc.data().displayPic : ""

            this.setState({profilePic : pic})
            document.getElementById("profileDropDown").hidden = false

            if(pic !== "")
              document.getElementById("profilePic").style.display = "block"
            else
              document.getElementById("profilePic").style.display = null

            const subscriptions = doc.data().subscriptions
              ? doc.data().subscriptions
              : []
            var includes = false
            for(var i = 0; i < subscriptions.length; i++) {
              if(subscriptions[i].id === stream.id){
                includes = true
              }
            }
            if(includes) {
              this.subscribeButton.current.innerHTML = "Subscribed"
            }
          })
      } else {
        this.setState({ hasUser: false })
      }
    })
  }

  getPincode() {
    if (typeof window !== `undefined`) {
      const search = window.location.search
      const params = new URLSearchParams(search)
      const pincode = params.get("pincode")
      return pincode
    } else {
      return ""
    }
  }

  async getAuthor() {
    var data
    const pincode = this.getPincode()
    if (pincode !== null && pincode !== undefined && pincode !== "") {
      await firebase
        .firestore()
        .collection("pins")
        .doc(pincode)
        .onSnapshot(doc => {
          if (doc.exists) {
            data = doc.data()
            this.title.current.innerHTML = data.content.text
            this.fileURL = data.content.file
            this.pincodeElem.current.innerHTML = "#" + pincode
            this.pincodeURLS = this.getURLSfromText(data.content.text)
            const stream = doc.data().stream
              ? doc.data().stream
              : ""

            this.setState({ stream: stream })
            this.getCurrentUser()
            this.getAuthorName(data.creator)
          }
        })
    }
  }

  getURLSfromText(pinText) {
    let geturl = new RegExp(
      // Strict regex to get only links with http
      "(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
      "g"
      // "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
      // "g"
    )

    let all_urls = pinText.match(geturl)
    if (all_urls && all_urls.length > 0) {
      return all_urls
    } else {
      return []
    }
  }

  async getAuthorName(creator) {
    const { stream } = this.state
    if (creator) {
      await firebase
        .firestore()
        .collection("users")
        .doc(creator.id)
        .get()
        .then(creatorDoc => {
          if (creatorDoc.exists) {
            const name = creatorDoc.data().name
             ? creatorDoc.data().name
               : ""
            this.owner.current.innerHTML = name

            this.setState({ creatorId: creator.id })
            if (this.state.userId === creator.id) {
              this.bookmark.current.disabled = true
              this.update.current.hidden = false
              this.shareButton.current.hidden = false
            } else {
              this.bookmark.current.hidden = false
              if(stream !== "") {
               this.subscribeButton.current.hidden = false
               this.subscribeButton.current.onclick = ()=> this.subscribe()
              }
            }
           if(this.state.hasUser === false) {
                 this.bookmark.current.hidden = true
                 this.subscribeButton.current.hidden = true
               }
          }
        })
    }
  }

  setShareInputs() {
    const pincode = this.getPincode()
    this.copyLink = window.location.href;
    this.copyPin = pincode
  }

  back() {
    window.location.href = "/home"
  }

  addBookmark() {
    const { userId } = this.state
    firebase
      .firestore()
      .collection("bookmarks")
      .doc(userId)
      .get()
      .then(bookmark => {
        if (bookmark.exists) {
          if (!bookmark.data().pincodes.includes(this.getPincode())) {
            var bookmarking1 = bookmark.data().pincodes
            bookmarking1.push(this.getPincode())
            firebase
              .firestore()
              .collection("bookmarks")
              .doc(userId)
              .set({ pincodes: bookmarking1 })
            this.bookmark.current.innerHTML = "Saved"
            this.bookmark.current.onclick = () => this.removeBookmark()
          }
        } else {
          var bookmarking = []
          bookmarking.push(this.getPincode())
          firebase
            .firestore()
            .collection("bookmarks")
            .doc(userId)
            .set({ pincodes: bookmarking })
          this.bookmark.current.innerHTML = "Saved"
          this.bookmark.current.onclick = () => this.removeBookmark()
        }
      })
  }

  removeBookmark() {
    const { userId } = this.state
    firebase
      .firestore()
      .collection("bookmarks")
      .doc(userId)
      .get()
      .then(bookmark => {
        if (bookmark.exists) {
          if (bookmark.data().pincodes.includes(this.getPincode())) {
            const array = bookmark.data().pincodes
            const index = array.indexOf(this.getPincode())
            array.splice(index, 1)
            firebase
              .firestore()
              .collection("bookmarks")
              .doc(userId)
              .set({ pincodes: array })
            this.bookmark.current.innerHTML = "Save"
            this.bookmark.current.onclick = () => this.addBookmark()
          }
        }
      })
  }

  bookmarked() {
    const { userId } = this.state
    firebase
      .firestore()
      .collection("bookmarks")
      .doc(userId)
      .get()
      .then(bookmark => {
        if (bookmark.exists) {
          if (
            bookmark.data().pincodes !== undefined &&
            bookmark.data().pincodes !== null
          ) {
            if (bookmark.data().pincodes.includes(this.getPincode())) {
              this.bookmark.current.innerHTML = "Saved"
              this.bookmark.current.onclick = () => this.removeBookmark()
            }
          }
        }
      })
  }

  hideEditModal() {
    this.setState({
      editOpen: false,
    })
  }

  launchEditModal() {
    this.setState({
      editOpen: true,
    })
  }

  deletePin() {
    firebase
      .firestore()
      .collection("pins")
      .doc(
        this.pincodeElem && this.pincodeElem.current
          ? this.pincodeElem.current.innerHTML.substr(1)
          : ""
      )
      .get().then((thisPin)=>{
        if(thisPin) {
          var file = thisPin.data().content.file ? thisPin.data().content.file : ""
          if(file !== "") {
            const store = firebase.storage().refFromURL(file);
            store.delete();
          }
        }
      })

    firebase
      .firestore()
      .collection("pins")
      .doc(
        this.pincodeElem && this.pincodeElem.current
          ? this.pincodeElem.current.innerHTML.substr(1)
          : ""
      )
      .delete()
      .then(() => {
        firebase.analytics()
          .logEvent('Pin Deleted', { name: 'Delete Pin'})
        firebase
          .firestore()
          .collection("comment")
          .doc(
            this.getPincode()
          ).delete()
          .then(() =>{
            window.location.href = "/pins"
         })
     })
  }

  subscribe() {
    const { userId, stream } = this.state
    var docRef =  firebase
        .firestore()
        .collection("users")
        .doc(userId)

    firebase.firestore().runTransaction(transaction => {
      return transaction.get(docRef).then(snapshot => {
        var subscriptions = snapshot.get('subscriptions');

        if(subscriptions === undefined)
          subscriptions = []

        var includes = false
        var index = ""
        for(var i = 0; i < subscriptions.length; i++) {
          if(subscriptions[i].id === stream.id) {
            includes = true
            index = i
          }
        }
        if(!includes) {
          subscriptions.push(stream);
          transaction.update(docRef, 'subscriptions', subscriptions);
          this.subscribeButton.current.innerHTML = "Subscribed"
        }
        else {
           this.subscribeButton.current.innerHTML = "Subscribe"
           subscriptions.splice(index, 1)
           transaction.update(docRef, 'subscriptions', subscriptions);
        }
      })
    })
  }

  onCancel() {
    this.onSetInputDialogOpen(false)
    navigate("/home/")
  }

  signOut = () => {
    firebase.auth().signOut()
  }

  render() {
    return (
      <div hidden={this.state.viewPin}>
        <Sidebar
        >
          
          <div className="d-flex">
           
            <div className="container-fluid">
              <div className="row">
              <div className="col-md-9">
              <div className="row pin-header-row">
              
              <div className="col-md-4 col-4">
                <ul className = "pin-header-wrapper">
                  <li style={{ display: "inline" }}>
                    <FaArrowLeft onClick={() => this.back()} style={{ cursor: "pointer" }}></FaArrowLeft>
                  </li>
                  <li style={{ display: "inline", paddingLeft: "10px", cursor: "pointer"}} onClick={() => this.back()}>
                    Pin
                  </li>
                </ul>
                </div>
                <div className="col-md-4 col-4">
                <img src = {Logo} className = "pin-logo"/>
                </div>
                <div className="col-md-4 col-4">

               <Dropdown id="profileDropDown" className = "profile-avatar" style={{ marginLeft: `60px` }} hidden>
                 <Dropdown.Toggle as={this.CustomToggle} style={{paddingTop: `10px` }}></Dropdown.Toggle>
                 <Dropdown.Menu>
                   <Dropdown.Item href="mailto:info@cloudpin.it">Get Help</Dropdown.Item>
                   <Dropdown.Item href="https://cloudpin.zendesk.com/hc/en-us">Leave Feedback</Dropdown.Item>
                   <Dropdown.Item
                     onClick={this.signOut}
                     href={"/signin"}
                   >
                     Sign Out
                   </Dropdown.Item>
                 </Dropdown.Menu>
               </Dropdown>
                </div>
                </div>
                
                <div className="row">
                <div className="col-md-12">
                <Search
                pincodeElem={this.pincodeElem}
                pincodeURLS={this.pincodeURLS}
                fileURL={this.fileURL}
                title={this.title}
                ownerImg={this.ownerImg}
                owner={this.owner}
                copyLink={this.copyLink}
                copyPin={this.copyPin}
                bookmark={this.bookmark}
                launchEditModal={this.launchEditModal}
                deletePin={this.deletePin}
                update={this.update}
                img={this.state.pic}
                share={this.shareButton}
                subscribe={this.subscribeButton}
                streamName={this.state.stream !== "" ? this.state.stream.id : "" }
              ></Search>
                </div>
                </div>
              
              
                </div>
              
              
              <div className="col-md-3 comment-section">
              <h4 className="comment-header">Comments</h4>
              <Comment pincode={this.getPincode()} />
              </div>
               </div>
               </div>
              
             
            
          </div>
        </Sidebar>
        <NewPinModal
          hideModal={this.hideEditModal}
          isOpen={this.state.editOpen}
          editMode={true}
          fileURL={this.fileURL}
          storedPinCode={
            this.pincodeElem.current ? this.pincodeElem.current.innerHTML : ""
          }
          storedPinText={this.title.current ? this.title.current.innerHTML : ""}
        />
        <InputModal
          show={this.state.inputDialogOpen}
          onHide={() => this.onCancel()}
          title={"Password Required to View Pin"}
          type="password"
          placeholder="Password"
          action={() => this.authPin()}
          inputRef={this.passwordInput}
          googleBtnAction={()=> this.deleteGoogleAccount()}
          googleBtnHidden={true}
          okayBtnHidden={false}
          inputHidden={false}
          errorHidden={this.state.errorHidden}
        ></InputModal>
      </div>
    )
  }
}

export default SearchComponent
